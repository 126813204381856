import { useEffect } from 'react';

function useMobileVhHack(): void {
  useEffect(() => {
    const handleResize = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
}

export default useMobileVhHack;
